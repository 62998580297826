import { ref } from 'vue'
import { auth } from '../firebase/config'
import deleteExpiredWishes from '@/composables/WishList/deleteExpiredWishes';

const error = ref(null)

const logIn = async (email, password) => {
  error.value = null

  try {
    const res = await auth.signInWithEmailAndPassword(email, password)
    error.value = null

    // await deleteExpiredWishes(res.user.uid);

    // console.log("Logged In")
    return res
  }
  catch(err) {
    // console.log(err.message)
    alert('Wrong Email Address or Password.')
    error.value = 'Wrong Email Address or Password.'
  }
}

const useLogIn = () => {
  return { error, logIn }
}

export default useLogIn