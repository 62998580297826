import { ref } from 'vue'
import { auth } from '../../firebase/config'

const UPRError = ref(null)

const sendPasswordResetEmail = async (email) => {
  UPRError.value = null

  try {
    await auth.sendPasswordResetEmail(email)
    alert("Success: Password Reset Email has been sent to " + email + ". Please check your email.")
  }
  catch(err) {
    if(err.message == "The email address is badly formatted."){
      alert("The email address is badly formatted. Please check your email address.")
    }
    else{
      alert("Failed to Send Password Reset Email.")
    }
    UPRError.value = err.message
  }
}

const usePasswordResetEmail = () => {
  return { UPRError, sendPasswordResetEmail }
}

export default usePasswordResetEmail