import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

const getIsSenior = async (isSenior,uid) => {
  try {
    await db.collection("Profiles").doc(uid).get().then(doc => {
        isSenior.value = doc.data().isSenior
    })
  } catch (error) {
    alert("getIsSenior " + error)
  }
};

export default wrapWithCache(getIsSenior, "isSenior")
